export function startLoading() {
	document.getElementById("loading").classList.remove("d-none");
	document.getElementById("header").classList.add("d-none");
	document.getElementById("main").classList.add("d-none");
	document.getElementById("footer").classList.add("d-none");
}

export function endLoading() {
	document.getElementById("loading").classList.add("d-none");
	document.getElementById("header").classList.remove("d-none");
	document.getElementById("main").classList.remove("d-none");
	document.getElementById("footer").classList.remove("d-none");
}