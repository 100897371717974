import * as menu from "./js/menu.js";
import * as language from "./js/language";
import * as employee from "./js/employee";
import * as loader from "./js/loader";

import "jquery";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "./css/style.css";

loader.startLoading();
try {
	load();
} catch(error) {
	console.error(error);
} finally {
	loader.endLoading();
}

async function load() {
	await language.awaitResources();
	language.addLanguages();
	language.autoSelectLanguage();
    
	await employee.awaitResources();
	employee.addEmployees("management", document.getElementById("empl-management"));
	employee.addEmployees("dispatching", document.getElementById("empl-dispatching"));
	employee.addEmployees("fumigation", document.getElementById("empl-fumigation"));
	employee.addEmployees("administration", document.getElementById("empl-administration"));
	employee.addEmployees("garage", document.getElementById("empl-garage"));
    
	menu.addSectionToNavItem("nav-home", "home");
	menu.addSectionToNavItem("nav-dispatching", "dispatching");
	menu.addSectionToNavItem("nav-fumigation", "fumigation");
	menu.addSectionToNavItem("nav-administration", "administration");
	menu.addSectionToNavItem("nav-garage", "garage");
	menu.addSectionToNavItem("nav-technology", "technology");
	menu.addSectionToNavItem("nav-contact", "contact");
    
	menu.switchTab("nav-home", "home");
}