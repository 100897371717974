//import * as employeeList from "../media/content/employees.json";
let employeeList;
import { Person } from "./person";

export async function awaitResources() {
	console.log("loading employees...");
	await fetch("./media/content/employees.json")
		.then(response => response.json())
		.then(data => employeeList = data);
}

export function addEmployees(department, element) {
	let employees = getEmployees(department);

	for (let i = 0; i < employees.length; i++) {
		let container = document.createElement("div");
		let card = document.createElement("div");
		let image = document.createElement("img");
		let body = document.createElement("div");
		let title = document.createElement("h5");
		let contact = document.createElement("p");

		container.classList.add("col-md-4");
		card.classList.add("card");
		image.classList.add("card-img-top");
		body.classList.add("card-body");
		body.classList.add("bg-secondary");
		title.classList.add("card-title");

		title.innerHTML = employees[i].firstName + " " + employees[i].lastName;

		if (employees[i].email !== "") {
			contact.innerHTML = "E-mail: " + employees[i].email + "<br>Tel: " + employees[i].phone;
		}

		if(employees[i].image === "") {
			image.src = "https://www.contextbv.com/wp-content/uploads/2020/03/Person-placeholder.jpg";
		} else {
			image.src = employees[i].image;
		}

		body.append(title);
		body.append(contact);
		card.append(image);
		card.append(body);
		container.append(card);

		element.append(container);
	}
}

function getEmployees(department) {
	let employees = [];

	for (let i = 0; i < employeeList.employees.length; i++) {
		if(employeeList.employees[i].department === department)
			employees.push(new Person(
				employeeList.employees[i].firstName,
				employeeList.employees[i].lastName,
				employeeList.employees[i].email,
				employeeList.employees[i].phone,
				employeeList.employees[i].image,
				employeeList.employees[i].department
			));
	}

	return employees;
}