export function addSectionToNavItem(link, section) {
	document.getElementById(link)
		.addEventListener("click", () => {
			removeActiveAttribute();
			hideAllSections();
			setActiveAttribute(link);
			showSection(section);
		});
	document.getElementById(link).href = "#";
}

export function switchTab(link, section) {
	removeActiveAttribute();
	hideAllSections();
	setActiveAttribute(link);
	showSection(section);
}

function removeActiveAttribute() {
	document.getElementsByClassName("active")[0].removeAttribute("class", "active");
}

function setActiveAttribute(id) {
	document.getElementById(id).parentElement.setAttribute("class", "active");
}

function hideAllSections()
{
	document.querySelectorAll("section").forEach(section => {
		section.setAttribute("class", "d-none");
	});
}

function showSection(id) {
	document.getElementById(id).removeAttribute("class", "d-none");
}