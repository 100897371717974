export class Person {
	constructor (
		firstName,
		lastName,
		email,
		phone,
		image,
		department
	) {
		this.firstName = firstName;
		this.lastName = lastName;
		this.email = email;
		this.phone = phone;
		this.image = image;
		this.department = department;
	}
}
