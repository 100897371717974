//import * as wordList from "../media/content/language.json";
let wordList;

export async function awaitResources() {
	console.log("loading languages...");
	await fetch("./media/content/language.json")
		.then(response => response.json())
		.then(data => wordList = data);
}

export function autoSelectLanguage() {
	let language = detectLanguage();
	selectLanguage(language);
}

export function selectLanguage(language) {
	let languages = wordList["lang-language"];
	let defaultLanguage = true;

	for (let i = 0; i < languages.length; i++) {
		if(language === languages[i]) {
			translateContent(i);
			defaultLanguage = false;
		}
	}

	if (defaultLanguage) {
		translateContent(0);
	}
}

export function addLanguages() {
	let languages = wordList["lang-language"];

	for (let i = 0; i < languages.length; i++) {
		let item = document.createElement("li");
		let content = document.createElement("a");
		
		item.classList.add("btn");
		content.classList.add("nav-link");
		content.href = "#";
		
		content.innerHTML = languages[i];

		item.append(content);

		item.addEventListener("click", () => {
			selectLanguage(languages[i]);
		});

		document.getElementById("language-selector").append(item);
	}
}

function detectLanguage() {
	let userLang = navigator.language;
	let language = userLang.split("-");

	return language[0].toLowerCase();
}

function translateContent(index) {
	let content = document.getElementsByClassName("lang");

	for (let i = 0; i < content.length; i++) {
		let element = content[i];
		let key = element.classList[element.classList.length - 1];
		let translations = wordList[key];
		element.innerHTML = translations[index];
	}
}